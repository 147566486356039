<h2 class="text-center">Welcome to the <span>{{forum?.name}}</span> Portal</h2>
<p class="text-left m-b-40">
  <a routerLink="/overarching-initiative-objective">OVERARCHING INITIATIVE OBJECTIVE</a><br/>
  <a routerLink="/your-task-in-completing-this-assignment">YOUR TASK IN COMPLETING THIS ASSIGNMENT</a>
</p>
<div class="row">
  <div class="col-sm-6">
    <h3>Modules</h3>
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="media" *ngFor="let module of modules">
          <div class="media-body">
            <h4 class="media-heading">
              <a routerLink="/forums/{{forum.id}}/modules/{{module.id}}">
                {{module.name}}
              </a>
            </h4>
            {{module.description}}
          </div>
          <div *ngIf="allSurveysSubmittedInModule(module)" class="media-right">
            <img src="/assets/img/completed.png" height="30px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div *ngIf="isModerator()">
      <h3>Moderators</h3>
      <div class="panel panel-default">
        <div class="panel-body p-0">
          <div class="media moderator" *ngFor="let moderator of moderators">
            <div class="media-body p-l-20 p-r-20">
              <h4 class="media-heading">
                {{moderator.client ? moderator.client.first_name : moderator.contact.first_name}}
                {{moderator.client ? moderator.client.last_name : moderator.contact.last_name}}
              </h4>
              {{moderator.client ? moderator.client.title : ''}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3>Submission Deadline</h3>
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="media moderator">
            <h4 class="m-t-0 m-b-0">Deadline for submissions is Jan 21 11:59 PM EST</h4>
            <br/>
            <app-countdown units="Days | Hours | Minutes" [text]="countdownConfig.text" [end]="deadlineDate"></app-countdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br/>
<br/>