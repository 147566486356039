import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PathLocationStrategy,
  LocationStrategy
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UserIdleModule } from 'angular-user-idle';

// Auth
import { AuthGuard } from './_guards/auth.guard';
import { AuthInterceptor } from './_interceptors/auth.interceptor';

// Services
import {
  AuthenticationService,
  CompanyService,
  ListService,
  ProfileService,
  ForumService,
  ForumSurveySubmissionService,
  QuestionService,
} from './_services';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from 'app/_components/shared.module';

import {
  SiteLayoutComponent,
  SiteHeaderComponent,
  SiteFooterComponent,
} from './_layouts';

import { LoginComponent } from './login/login.component';
import { DummyComponent } from './_components/dummy.component';

import { HomeComponent } from './forum/home.component';
import { ForumDetailComponent } from './forum/detail.component';
import { ForumModuleDetailComponent } from './forum/module-detail.component';
import { ForumSurveyDetailComponent } from './forum/survey-detail.component';
import { SurveySubmissionModalComponent } from './forum/survey-submission-modal.component';
import { SurveyConfirmationModalComponent } from './forum/survey-confirmation-modal.component';
import { ForumResourceDetailComponent } from './forum/resource-detail.component';
import { HelpComponent } from './help/help.component';
import { ForumResourceListComponent } from './forum/resource-list.component';
import { DirectoryComponent } from './forum/directory.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NeedHelpModalComponent } from './login/need-help-modal.component';
import { AlertModalComponent } from './forum/alert-modal.component';
import { Page1Component } from './help/page1.component';
import { Page2Component } from './help/page2.component';

import { USER_IDLE_CONFIG } from './app.constants';
import { UserIdleModalComponent } from './user-idle-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent,

    LoginComponent,
    DummyComponent,

    HomeComponent,
    ForumDetailComponent,
    ForumModuleDetailComponent,
    ForumSurveyDetailComponent,
    SurveySubmissionModalComponent,
    SurveyConfirmationModalComponent,
    ForumResourceDetailComponent,
    HelpComponent,
    ForumResourceListComponent,
    DirectoryComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    NeedHelpModalComponent,
    AlertModalComponent,
    Page1Component,
    Page2Component,

    UserIdleModalComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,

    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
    }),
    PdfViewerModule,
    UserIdleModule.forRoot(USER_IDLE_CONFIG),
  ],
  entryComponents: [
    SurveySubmissionModalComponent,
    SurveyConfirmationModalComponent,
    UserIdleModalComponent,
    NeedHelpModalComponent,
    AlertModalComponent,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    CompanyService,
    ListService,
    ProfileService,
    ForumService,
    ForumSurveySubmissionService,
    QuestionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
