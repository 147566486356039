<div class="row">
  <div class="col-sm-6">
    <div>
      <h3>Resources</h3>
      <p>* Printing and downloading resources has been disabled</p>
      <div class="panel panel-default">
        <div class="panel-body p-0">
          <ng-template ngFor let-resource [ngForOf]='resources'>
            <div class="media moderator">
              <div class="media-body p-l-20 p-r-20">
                <h4 class="media-heading">
                  <a href="javascript:void(0)" (click)="openPopup(resource.id)">
                    {{resource.filename}}
                  </a>
                </h4>
                {{resource.description}}
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6" *ngIf="isModerator()">
    <div>
      <h3>Reports</h3>
      <div class="panel panel-default">
        <div class="panel-body p-0">
          <div class="media moderator">
            <div class="media-body p-l-20 p-r-20">
              <h4 class="media-heading">
                <a href="javascript:void(0)" (click)="downloadSubmissionReport('')">
                  Global Report
                </a>
              </h4>
              <h4 class="m-t-20 m-b-0">Individual Reports</h4>
              <h4 class="media-heading">
                <ul>
                  <li *ngFor="let person of participants">
                    <a href="javascript:void(0)" (click)="downloadSubmissionReport(person.contact_id)">
                      {{person.contact.last_name}}, {{person.contact.first_name}}
                    </a>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<br />