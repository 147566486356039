<div class="modal-header">
  <h4 class="modal-title pull-left">&nbsp;</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3 style="font-weight: bold">OVERARCHING INITIATIVE OBJECTIVE</h3>
  <ul>
    <li>
      To determine the feasibility of relating line items from commonly
      used major depressive disorder (MDD) and schizophrenia (SZ) clinical
      research scales to the Research Domain Criteria (RDoC) Domain matrix.
    </li>
    <li>
      The clinical research scales used in this initiative include: MADRS,
      HAM-D17, IDS-SR, HAM-A, SDS, PANSS and the PSP.
      <ul>
        <li>
          Keep in mind that at this stage this initiative is a feasibility
          study and that future clinical research scales may be added to
          represent additional mental health disorders, perspectives
          (e.g. self-report, clinician-report) and topics
          (e.g. functioning, cognition).
        </li>
        <li>
          Also note that we may find that commonly used scale items don't neatly
          fit into the RDoC Domain framework, or that the scale items are unevenly
          represented within the framework. Such discrepancies and nuances will
          build the foundation of our discussion on February 1st.
        </li>
      </ul>
    </li>
  </ul>
  <h3 style="font-weight: bold">YOUR TASK IN COMPLETING THIS ASSIGNMENT</h3>
  <ul>
    <li>
      To complete this assignment, please review all the scale items and determine
      which RDoC domain is the best fit.
      <ul>
        <li>
          To help you do this, please visit the additional resources section of this platform. There you will find
          content that describes the RDoC domains in addition to copies of the actual scales, which can provide
          additional context behind the intent of a scale item (i.e. additional line item descriptions, and the spectrum
          of possible answers). This information should help inform your decisions while you complete this assignment.
        </li>
        <li>
          <span style="font-weight: bold">In response to each scale item you can do one of the following:</span>
          <ul>
            <li>Choose 1 out of the 6 RDoC domain items</li>
            <li>Choose multiple RDoC domain items</li>
            <li>Choose N/A to any of the RDoC domain items.
              <ul>
                <li style="font-style: italic;">PLEASE REFRAIN FROM USING THIS OPTION TOO FREQUENTLY. TRY TO FIND THE BEST FIT FOR A SCALE ITEM,
                  EVEN IF IT MAY FIT MORE THAN ONE DOMAIN. SAVE THE N/A ANSWER FOR THOSE ITEMS WHICH YOU FEEL TRULY HAVE
                  NO HOME IN THE RDoC DOMAIN FRAMEWORK.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
