<div class="modal-header">
  <h4 class="modal-title pull-left">Please review. Once submitted you will not be able to edit your responses.</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="max-height: 500px;overflow-y: auto;">
  <div>
    <div *ngFor="let answer of multiRadioAnswers" class="panel panel-default">
      <div class="panel-heading">{{getChoice(answer)}}</div>
      <div class="panel-body">
        <div *ngFor="let sq of survey?.survey_questions">
          <div *ngIf="answers[sq.question_id] && answers[sq.question_id].includes(answer)">
            <span [tooltip]="getUnboldText(questions[sq.question_id].text)" placement="bottom"
              [innerHTML]="getBoldText(questions[sq.question_id].text)"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-custom" (click)="onSubmit()">Submit</button>
</div>