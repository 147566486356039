import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class ForumService extends BaseService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/companies/%(company_id)s/forums`);
  }

  getModerators(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/moderators`, urlParams);
    return this.http.get<any>(url);
  }

  getTopics(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/topics`, urlParams);
    return this.http.get<any>(url);
  }

  getModules(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/modules`, urlParams);
    return this.http.get<any>(url);
  }

  getSurveys(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/surveys`, urlParams);
    return this.http.get<any>(url);
  }

  getResources(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/resources`, urlParams);
    return this.http.get<any>(url);
  }

  getPersonnel(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/personnel`, urlParams);
    return this.http.get<any>(url);
  }

  getParticipants(urlParams: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/participants`, urlParams);
    return this.http.get<any>(url);
  }

  updateParticipant(urlParams: any, data: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/participants/%(contact_id)s`, urlParams);
    return this.http.put<any>(url, data);
  }

  updatePersonnel(urlParams: any, data: any): Observable<any> {
    const url = sprintf(`${environment.apiBaseUrl}/companies/%(company_id)s/forums/%(forum_id)s/personnel/%(client_id)s`, urlParams);
    return this.http.put<any>(url, data);
  }
}
