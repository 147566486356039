import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { getChoiceText, getTooltipText } from 'app/_utils/helpers';

@Component({
  selector: 'app-survey-confirmation-modal',
  templateUrl: './survey-confirmation-modal.component.html',
  styleUrls: ['./survey-confirmation-modal.component.css'],
})
export class SurveyConfirmationModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  status: string;
  questions: any;
  survey: any;
  answers: any;
  multiRadioAnswers: string[];
  constructor(
    public bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
    const sq = this.survey.survey_questions.find(s => this.questions[s.question_id].type_id.includes('MULTIPLE_'));
    this.multiRadioAnswers = this.questions[sq.question_id].choices.split('\n');
  }

  getChoice(value) {
    return getChoiceText(value);
  }

  getBoldText(text) {
    if (text.includes('</b>')) {
      let [ret] = text.split('</b>');
      ret = `${ret}</b>`.replace(/\s*:\s*/, '');
      return ret;
    }
    return text;
  }

  getUnboldText(text) {
    if (text.includes('</b>')) {
      const [bold, ret] = text.split('</b>');
      return ret.trim();
    }
    return text;
  }

  onSubmit() {
    this.bsModalRef.hide();
    this.event.emit();
  }
}
