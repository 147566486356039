import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserIdleModalComponent } from './user-idle-modal.component';
import { AuthenticationService } from './_services';
import { USER_IDLE_CONFIG } from './app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'enovaforums';
  bsUserIdleModalRef: BsModalRef;

  constructor(
    private authenticationService: AuthenticationService,
    private userIdleService: UserIdleService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    // Start watching for user inactivity.
    this.userIdleService.startWatching();

    // Start watching when user idle is starting.
    this.userIdleService.onTimerStart().subscribe((count) => {
      if (count === 1) {
        if (this.authenticationService.id()) {
          const openModals = document.querySelectorAll('.modal.in');
          if (openModals) {
            openModals.forEach((openModal) => {
              const modalHeader = openModal.getElementsByClassName('modal-header');
              if (modalHeader && modalHeader.length > 0) {
                const closeButton: any = modalHeader[0].getElementsByTagName('BUTTON');
                if (closeButton && closeButton.length > 0) {
                  closeButton[0].click();
                }
              }
            });
          }
          this.bsUserIdleModalRef = this.modalService.show(UserIdleModalComponent, {
            initialState: USER_IDLE_CONFIG,
            class: 'modal-sm'
          });
        } else {
          this.userIdleService.stopTimer();
          this.userIdleService.resetTimer();
        }
      }
    });
  }
}
