import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService, ForumSurveySubmissionService } from '../_services';
import { AlertModalComponent } from './alert-modal.component';

@Component({
  selector: 'app-forum',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class ForumDetailComponent extends BaseComponent implements OnInit {
  forum: any;
  modules: any[];
  moderators: any[];
  submissions: any;
  deadlineDate: string;
  countdownConfig: any;

  constructor(
    authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private forumService: ForumService,
    private forumSurveySubmissionService: ForumSurveySubmissionService,
    private modalService: BsModalService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.deadlineDate = '2020-01-22T00:00:00';
    this.countdownConfig = {
      text: {
        Days: 'Days',
        Hours: 'Hrs',
        Minutes: 'Mins remaining to deadline',
      },
    };
    this.submissions = {};
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.forumService.get(params.id, {
          company_id: this.authenticationService.current_company_id(),
        }).subscribe((forum) => {
          this.forum = forum;
          this.forumService.getModules({
            company_id: this.authenticationService.current_company_id(),
            forum_id: this.forum.id
          }).subscribe((modules) => {
            this.modules = modules;
          });
          this.forumService.getModerators({
            company_id: this.authenticationService.current_company_id(),
            forum_id: this.forum.id
          }).subscribe((moderators) => {
            this.moderators = moderators;
            if (this.authenticationService.contact_id()) {
              const participant = this.moderators.find(p => p.contact_id === this.authenticationService.contact_id());
              if (participant && !participant.alert) {
                this.forumService.updateParticipant({
                  company_id: this.authenticationService.current_company_id(),
                  forum_id: this.forum.id,
                  contact_id: participant.contact_id,
                }, { alert: true }).subscribe();
                this.modalService.show(AlertModalComponent);
              }
            }
            if (this.authenticationService.client_id()) {
              const client = this.moderators.find(p => p.client_id === this.authenticationService.client_id());
              if (client && !client.alert) {
                this.forumService.updatePersonnel({
                  company_id: this.authenticationService.current_company_id(),
                  forum_id: this.forum.id,
                  client_id: client.client_id,
                }, { alert: true }).subscribe();
                this.modalService.show(AlertModalComponent);
              }
            }
          });
        });
        this.forumSurveySubmissionService.list({
          company_id: this.authenticationService.current_company_id(),
        }, {
          forum_id: params.id,
          user_id: this.authenticationService.id(),
          status: 'submitted'
        }).subscribe((submissions) => {
          submissions.forEach(s => {
            if (!this.submissions[s.forum_module_id]) {
              this.submissions[s.forum_module_id] = {};
            }
            this.submissions[s.forum_module_id][s.forum_survey_id] = true;
          });
        });
      }
    });
  }

  allSurveysSubmittedInModule(module) {
    let ret = true;
    module.forum_module_surveys.forEach(s => {
      if (!this.submissions[s.forum_module_id] || !this.submissions[s.forum_module_id][s.forum_survey_id]) {
        ret = false;
      }
    });
    return ret;
  }
}
