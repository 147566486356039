<nav class="navbar">
  <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
    <div id="account_nav">
      <div class="container">
        <ul class="nav navbar-nav">
          <li><a href="#">&nbsp;</a></li>
        </ul>
      </div>
      <div class="clearfix"></div>
    </div>
    <div id="main_nav">
      <div class="container">
        <div class="navbar-header">
          <a class="navbar-brand" href="#">
            <img src="/assets/img/logo.jpg">
          </a>
        </div>
        <ul class="nav navbar-nav">
          <li><a href="#">&nbsp;</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<section id="wrapper">
  <div class="login-box">
    <div class="green-box">
      <form name="loginform" id="loginform" (ngSubmit)="login()" novalidate>
        <h2 class="text-center m-t-0">Login</h2>
        <div *ngIf="error" class="alert alert-danger m-t-40">{{error}}</div>
        <div class="form-group">
          <label>User Name</label>
          <input type="text" [(ngModel)]="model.username" class="form-control" name="username" />
        </div>
        <div class="form-group">
          <label>Password</label>
          <input type="password" [(ngModel)]="model.password" class="form-control" name="password" />
        </div>
        <div class="row">
          <div class="col-sm-6">
            <button id="login" class="btn" [disabled]="loading">Login</button>
          </div>
          <div class="col-sm-6 text-right">
            <a id="help" href="javascript:void(0)" (click)="needHelp()">
              Need help logging in? <i class="fa fa-check-circle"></i>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<br/>
<br/>
<app-site-footer></app-site-footer>