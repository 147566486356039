import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
})
export class AlertModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
  }
}
