<h2 class="text-center m-b-40">Directory</h2>
<table class="table table-striped table-bordered">
  <thead>
    <tr>
      <th>Name</th>
      <th>Role</th>
      <th>Completed</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let person of getSortedList()">
      <td *ngIf="person.client">{{person.client | fullName}}</td>
      <td *ngIf="person.contact">{{person.contact | fullName}}</td>
      <td>{{person.role}}</td>
      <td>{{submissions[person.user_id] || 0}}</td>
    </tr>
  </tbody>
</table>
<br/>
<br/>
<br/>
<br/>