<div class="panel p-30" style="position:relative;">
  <h3 *ngIf="isModerator()">FOR REFERENCE ONLY</h3>
  <div *ngIf="submitted" class="alert alert-success" style="background:#22b3ac;border-color: #22b3ac;">
    THIS ASSIGNMENT HAS BEEN SUBMITTED</div>
  <div *ngFor="let sq of survey?.survey_questions;index as i">
    <app-question *ngIf="questions[sq.question_id]" [question]="questions[sq.question_id]" [answers]="answers"
      [disabled]="submitted || isModerator()" [index]="getQuestionIndex(sq.question_id)">
    </app-question>
  </div>
  <div *ngIf="!submitted && !isModerator()">
    <button type="button" class="btn btn-default" (click)="submitSurvey('draft')">Save</button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()"
      [tooltip]="!allQuestionsAnswered() ? 'All questions must be answered prior to submission.' : ''"
      triggers="focus" [ngClass]="{'disabled':!allQuestionsAnswered()}">Review and Submit</button>
  </div>
  <app-scroll-top></app-scroll-top>
</div>
<br />
<br />
<br />
<br />