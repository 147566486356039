<h2 class="text-center m-b-40">{{module?.name}}</h2>
<div class="row">
  <div class="col-sm-6">
    <h3>Assignment</h3>
    <div class="panel panel-default">
      <div class="panel-body">
        <ng-template ngFor let-survey [ngForOf]='surveys'>
          <div class="media" *ngIf="surveyMap[survey.id]">
            <div class="media-body">
              <h4 class="media-heading">
                <a routerLink="/forums/{{forumId}}/modules/{{module.id}}/surveys/{{survey.id}}">
                  {{survey.name}}
                </a>
              </h4>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div>
      <h3>Resource</h3>
      <div class="panel panel-default">
        <div class="panel-body p-b-10">
          <ng-template ngFor let-resource [ngForOf]='resources'>
            <div class="media moderator" *ngIf="resourceMap[resource.id]">
              <div class="media-body p-l-20 p-r-20">
                <h4 class="media-heading">
                  <a href="javascript:void(0)" (click)="openPopup(resource.id)">
                    {{resource.filename}}
                  </a>
                </h4>
                {{resource.description}}
              </div>
            </div>
          </ng-template>
          * Printing and downloading resources has been disabled
        </div>
      </div>
    </div>
  </div>
</div>
<br/>
<br/>