<footer>
  <div class="container" style="padding: 5px 0;">
    <div class="row">
      <div class="col-md-4">
        <a routerLink="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a>
      </div>
      <div class="col-md-4 text-center" style="line-height: 17px;font-weight: bold;">
        FOR AD BOARD USE ONLY.<br />
        DO NOT DUPLICATE OR DISTRIBUTE.
      </div>
      <div class="col-md-4 text-right">
        Radius Direct &copy; 2020. All Rights Reserved
      </div>
    </div>
  </div>
</footer>