import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-need-help-modal',
  templateUrl: './need-help-modal.component.html',
})
export class NeedHelpModalComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
  }
}
