import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { SiteLayoutComponent } from './_layouts';

import { LoginComponent } from './login/login.component';
import { DummyComponent } from './_components/dummy.component';
import { HomeComponent } from './forum/home.component';
import { ForumDetailComponent } from './forum/detail.component';
import { ForumModuleDetailComponent } from './forum/module-detail.component';
import { ForumSurveyDetailComponent } from './forum/survey-detail.component';
import { ForumResourceDetailComponent } from './forum/resource-detail.component';
import { HelpComponent } from './help/help.component';
import { ForumResourceListComponent } from './forum/resource-list.component';
import { DirectoryComponent } from './forum/directory.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { Page1Component } from './help/page1.component';
import { Page2Component } from './help/page2.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: SiteLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'forums/:id', component: ForumDetailComponent },
      { path: 'forums/:forum_id/modules/:id', component: ForumModuleDetailComponent },
      { path: 'forums/:forum_id/modules/:module_id/surveys/:id', component: ForumSurveyDetailComponent },
      { path: 'resources', component: ForumResourceListComponent },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      { path: 'help', component: HelpComponent },
      { path: 'directory', component: DirectoryComponent },
      { path: 'overarching-initiative-objective', component: Page1Component },
      { path: 'your-task-in-completing-this-assignment', component: Page2Component },
    ]
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: 'terms-of-use', component: TermsOfUseComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
    ]
  },
  { path: 'forums/:forum_id/resources/:id', component: ForumResourceDetailComponent },
  { path: 'dummy', component: DummyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
