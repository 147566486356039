<div class="modal-header">
  <h4 class="modal-title pull-left" *ngIf="status !== 'draft'">
    You have successfully submitted {{survey.name}}
  </h4>
  <h4 class="modal-title pull-left" *ngIf="status === 'draft'">
    You have successfully saved
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="status === 'draft'">
    Thank you for saving. Please remember, this assignment still needs to be submitted.
  </p>
  <p *ngIf="status !== 'draft'">
    Thank you for submitting {{survey.name}}
  </p>
  <div>
    <a href="javascript:void(0)" (click)="returnHome()" class="btn btn-custom">Click here to return to the home page</a>
  </div>
</div>