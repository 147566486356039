import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService, QuestionService, ForumSurveySubmissionService } from '../_services';
import { SurveySubmissionModalComponent } from './survey-submission-modal.component';
import { SurveyConfirmationModalComponent } from './survey-confirmation-modal.component';

@Component({
  selector: 'app-forum-survey-details',
  templateUrl: './survey-detail.component.html',
})
export class ForumSurveyDetailComponent extends BaseComponent implements OnInit {
  forumId: number;
  moduleId: number;
  surveyId: number;
  forum: any;
  module: any;
  survey: any;
  questions: any;
  answers: any;
  submitted: boolean;
  confirmationModalRef: BsModalRef;

  constructor(
    authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private forumService: ForumService,
    private questionService: QuestionService,
    private forumSurveySubmissionService: ForumSurveySubmissionService,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.questions = {};
    this.answers = {};
    this.submitted = true;
    this.questionService.list({ company_id: this.getCurrentCompanyId() }, { appearance: 'survey' })
      .subscribe(questions => {
        questions.forEach(q => {
          this.questions[q.id] = q;
        });
      });
    this.route.params.subscribe((params) => {
      this.forumId = params.forum_id;
      this.moduleId = params.module_id;
      this.surveyId = params.id;
      this.forumService.get(this.forumId, {
        company_id: this.authenticationService.current_company_id(),
      }).subscribe((forum) => {
        this.forum = forum;
      });
      this.forumService.getModules({
        company_id: this.authenticationService.current_company_id(),
        forum_id: params.forum_id
      }).subscribe((modules) => {
        this.module = modules.find(m => m.id.toString() === this.moduleId);
      });
      this.forumService.getSurveys({
        company_id: this.authenticationService.current_company_id(),
        forum_id: params.forum_id
      }).subscribe((surveys) => {
        this.survey = surveys.find(s => s.id.toString() === this.surveyId);
      });
      this.forumSurveySubmissionService.list({
        company_id: this.authenticationService.current_company_id(),
      }, {
        forum_module_id: this.moduleId,
        forum_survey_id: this.surveyId,
        user_id: this.authenticationService.id(),
      }).subscribe((submissions) => {
        if (submissions.length) {
          if (submissions[0].status !== 'submitted') {
            this.submitted = false;
          }
          submissions[0].answers.forEach((a) => {
            this.answers[a.question_id] = a.answer;
          });
        } else {
          this.submitted = false;
        }
      });
    });
  }

  showThankyouPopup(status) {
    this.modalService.show(SurveySubmissionModalComponent, {
      initialState: {
        status,
        survey: this.survey,
      },
      class: 'modal-lg'
    });
  }

  allQuestionsAnswered() {
    if (this.survey) {
      let ret = true;
      this.survey.survey_questions.forEach((sq) => {
        if (this.questions && this.questions[sq.question_id] && this.questions[sq.question_id].type_id.includes('MULTIPLE_')) {
          if (!this.answers[sq.question_id]) {
            ret = false;
          }
        }
      });
      return ret;
    }
    return false;
  }

  onSubmit() {
    if (this.allQuestionsAnswered()) {
      this.submitSurvey('submitted');
    }
  }

  submitSurvey(status) {
    if (status === 'draft') {
      this.forumSurveySubmissionService.create({
        forum_module_id: this.moduleId,
        forum_survey_id: this.surveyId,
        status,
        answers: Object.keys(this.answers).map(questionId => ({
          question_id: questionId,
          answer: this.answers[questionId],
        })),
      }, {
        company_id: this.authenticationService.current_company_id(),
      }).subscribe((res) => {
        this.toastr.success('Saved!');
        this.showThankyouPopup(status);
      });
    } else {
      this.confirmationModalRef = this.modalService.show(SurveyConfirmationModalComponent, {
        initialState: {
          status,
          questions: this.questions,
          survey: this.survey,
          answers: this.answers,
        },
        class: 'modal-lg'
      });
      this.confirmationModalRef.content.event.subscribe(res => {
        this.forumSurveySubmissionService.create({
          forum_module_id: this.moduleId,
          forum_survey_id: this.surveyId,
          status,
          answers: Object.keys(this.answers).map(questionId => ({
            question_id: questionId,
            answer: this.answers[questionId],
          })),
        }, {
          company_id: this.authenticationService.current_company_id(),
        }).subscribe(() => {
          this.toastr.success('Submitted!');
          this.showThankyouPopup(status);
        });
      });
    }
  }

  getQuestionIndex(questionId) {
    let ret = 0;
    for (let i = 0; i < this.survey.survey_questions.length; i++) {
      const sq = this.survey.survey_questions[i];
      if (this.questions[sq.question_id].type_id.includes('MULTIPLE_')) {
        ret++;
      }
      if (sq.question_id === questionId) {
        break;
      }
    }
    return ret;
  }
}
