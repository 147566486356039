<div [ngClass]="{'p-l-30': index > 0, 'p-r-30': index > 0}">
  <div *ngIf="index > 1">
    <hr>
  </div>
  <div class="h4" *ngIf="question.type_id!=='HEADER' && question.type_id!=='PARAGRAPH'">
    <div *ngIf="index" style="display:inline-block;font-weight:800;float:left;height:100px;text-align:right;"
      [style.width.px]="indexWidth">{{index}}.&nbsp;</div>
    <div [innerHTML]="question.text"></div>
  </div>
  <div *ngIf="question.type_id==='TF'">
    <select name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
      <option [ngValue]="value" *ngFor="let value of ['', 'True', 'False']">{{value}}</option>
    </select>
  </div>
  <div *ngIf="question.type_id==='YN' || question.type_id==='food_served' || question.type_id==='collaborate'">
    <div class="radio radio-info" *ngFor="let value of ['', 'Yes', 'No'];index as i">
      <input type="radio" name="answer_{{question.id}}" [value]="value" [(ngModel)]="answers[question.id]"
        id="answer_{{question.id}}_{{i}}">
      <label for="answer_{{question.id}}_{{i}}"> {{value === '' ? 'N/A' : value}}</label>
    </div>
  </div>
  <div *ngIf="question.type_id==='CHECKBOX'">
    <div class="checkbox">
      <input type="checkbox" name="answer_{{question.id}}" value="true" [(ngModel)]="answers[question.id]">
      <label for="answer_{{question.id}}"></label>
    </div>
  </div>
  <div *ngIf="question.type_id==='PRE_CHECKBOX'">
    <div class="checkbox">
      <input type="checkbox" name="answer_{{question.id}}" value="true" [(ngModel)]="answers[question.id]">
      <label for="answer_{{question.id}}"></label>
    </div>
  </div>
  <div *ngIf="question.type_id==='RATING'">
    <rating [(ngModel)]="answers[question.id]" max="5"></rating>
  </div>
  <div *ngIf="question.type_id==='COMMENTS' || question.type_id==='TEXT_AREA'">
    <textarea rows="4" cols="60" name="answer_{{question.id}}" class="form-control"
      [(ngModel)]="answers[question.id]"></textarea>
  </div>
  <div *ngIf="question.type_id==='TEXT_FIELD'">
    <input type="text" name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
  </div>
  <div [style.paddingLeft.px]="indexWidth * 2" *ngIf="question.type_id==='MULTIPLE_RADIO'">
    <ng-template ngFor let-value let-i="index" [ngForOf]='question.choices.split("\n")'>
      <div class="radio radio-info" *ngIf="value">
        <input type="radio" name="answer_{{question.id}}_{{i}}" [value]="value" [(ngModel)]="answers[question.id]"
          id="answer_{{question.id}}_{{i}}" [disabled]="disabled">
        <label for="answer_{{question.id}}_{{i}}" [tooltip]="getTooltip(value)" placement="bottom">
          {{getChoice(value)}}</label>
      </div>
    </ng-template>
  </div>
  <div [style.paddingLeft.px]="indexWidth * 2" *ngIf="question.type_id==='MULTIPLE_CHECK'">
    <ng-template ngFor let-value let-i="index" [ngForOf]='question.choices.split("\n")'>
      <div class="checkbox" *ngIf='value'>
        <input type="checkbox" name="answer_{{question.id}}_{{i}}" [value]="value" [checked]="hasAnswer(value)"
          (change)="toggleAnswer(value)" [disabled]="disabled || isDisabledAnswer(value)">
        <label for="answer_{{question.id}}_{{i}}" [tooltip]="getTooltip(value)" placement="bottom">
          {{getChoice(value)}}</label>
      </div>
    </ng-template>
  </div>
  <div *ngIf="question.type_id==='NUMBER' || question.type_id==='speaker_count'">
    <input type="text" name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
  </div>
  <div class="h3 m-t-10 m-b-20 p-b-10" *ngIf="question.type_id==='HEADER'" [innerHTML]="question.text">
  </div>
  <p *ngIf="question.type_id==='PARAGRAPH'" class="alert alert-success p-10" [innerHTML]="question.text"
    style="background:#22b3ac;border-color: #22b3ac;"></p>
  <div
    *ngIf="question.type_id==='AMOUNT' || question.type_id==='attendee_count_estimate' || question.type_id==='client_count_estimate'">
    <input type="text" name="answer_{{question.id}}" class="form-control" [(ngModel)]="answers[question.id]">
  </div>
  <div *ngIf="question.type_id==='DATE'">
    <input type="text" name='answer_{{question.id}}' [(ngModel)]="answers[question.id]"
      class="form-control datetimepicker" bsDatepicker>
  </div>
  <div *ngIf="question.type_id==='FILE'">
    <div *ngIf="answers[question.id]">{{answers[question.id].name}}</div>
    <input type="file" name="answer_{{question.id}}" class="dropify" (change)="onFileChange($event)" />
  </div>
</div>