<h2 style="font-weight: bold;">YOUR TASK IN COMPLETING THIS ASSIGNMENT</h2>
<ul style="font-size: 18px;">
  <li>
    To complete this assignment, please review all the scale items and determine
    which RDoC domain is the best fit.
    <ul>
      <li>
        To help you do this, please visit the additional resources section of this platform. There you will find
        content that describes the RDoC domains in addition to copies of the actual scales, which can provide
        additional context behind the intent of a scale item (i.e. additional line item descriptions, and the spectrum
        of possible answers). This information should help inform your decisions while you complete this assignment.
      </li>
      <li>
        <span style="font-weight:bold;">In response to each scale item you can do one of the following:</span>
        <ul>
          <li>Choose 1 out of the 6 RDoC domain items</li>
          <li>Choose multiple RDoC domain items</li>
          <li>Choose N/A to any of the RDoC domain items.
            <ul>
              <li style="font-style: italic;">PLEASE REFRAIN FROM USING THIS OPTION TOO FREQUENTLY. TRY TO FIND THE BEST FIT FOR A SCALE ITEM,
                EVEN IF IT MAY FIT MORE THAN ONE DOMAIN. SAVE THE N/A ANSWER FOR THOSE ITEMS WHICH YOU FEEL TRULY HAVE
                NO HOME IN THE RDoC DOMAIN FRAMEWORK.</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
