import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService } from '../_services';

@Component({
  selector: 'app-forum-resource-details',
  templateUrl: './resource-detail.component.html',
})
export class ForumResourceDetailComponent extends BaseComponent implements OnInit {
  forumId: number;
  resourceId: number;
  resource: any;
  pdfSrc: string;

  constructor(
    authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private forumService: ForumService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    this.route.params.subscribe((params) => {
      this.forumId = params.forum_id;
      this.resourceId = params.id;
      this.forumService.getResources({
        company_id: this.authenticationService.current_company_id(),
        forum_id: params.forum_id
      }).subscribe((resources) => {
        this.resource = resources.find(r => r.id.toString() === this.resourceId);
        this.pdfSrc = this.resource.s3path;
      });
    });
  }
}
