<div class="modal-header">
  <h4 class="modal-title pull-left">Need help logging in?</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Should you have any questions or require technical assistance call 888-444-4117<br/>
    Monday – Friday from 9 AM – 5 PM ET or email Neslihan Oz at
    <a href="mailto:noz@teamenova.com">noz@teamenova.com</a>.
  </p>
</div>