import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService } from '../_services';

@Component({
  selector: 'app-forum-module-details',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class ForumModuleDetailComponent extends BaseComponent implements OnInit {
  forumId: number;
  module: any;
  surveys: any[];
  resources: any[];
  surveyMap: any;
  resourceMap: any;

  constructor(
    authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private forumService: ForumService,
    protected http: HttpClient,
  ) {
    super(authenticationService);
  }

  openPopup(resourceId) {
    window.open(`/forums/${this.forumId}/resources/${resourceId}`, 'MyWindow', 'width=1000,height=800');
    return false;
  }

  ngOnInit() {
    this.surveyMap = {};
    this.resourceMap = {};
    this.route.params.subscribe((params) => {
      this.forumId = params.forum_id;
      this.forumService.getModules({
        company_id: this.authenticationService.current_company_id(),
        forum_id: params.forum_id
      }).subscribe((modules) => {
        this.module = modules.find(m => m.id.toString() === params.id);
        this.module.forum_module_surveys.forEach(m => {
          this.surveyMap[m.forum_survey_id] = true;
        });
        this.module.forum_module_resources.forEach(m => {
          this.resourceMap[m.forum_resource_id] = true;
        });
      });
      this.forumService.getSurveys({
        company_id: this.authenticationService.current_company_id(),
        forum_id: params.forum_id
      }).subscribe((surveys) => {
        this.surveys = surveys;
      });
      this.forumService.getResources({
        company_id: this.authenticationService.current_company_id(),
        forum_id: params.forum_id
      }).subscribe((resources) => {
        this.resources = resources;
      });
    });
  }
}
