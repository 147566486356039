import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page1',
  templateUrl: './page1.component.html',
})
export class Page1Component implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }
}
