import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService, ForumSurveySubmissionService } from '../_services';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
})
export class DirectoryComponent extends BaseComponent implements OnInit {
  forumId: number;
  personnel: any[];
  participants: any[];
  submissions: any;
  list: any[];

  constructor(
    authenticationService: AuthenticationService,
    private forumService: ForumService,
    private forumSurveySubmissionService: ForumSurveySubmissionService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.list = [];
    this.submissions = {};
    this.forumService.list({
      company_id: this.getCurrentCompanyId(),
    }, {}).subscribe((forums) => {
      this.forumId = forums[0].id;
      this.forumService.getPersonnel({
        company_id: this.authenticationService.current_company_id(),
        forum_id: this.forumId
      }).subscribe((personnel) => {
        this.personnel = personnel;
        this.list = this.list.concat(this.personnel);
      });
      this.forumService.getParticipants({
        company_id: this.authenticationService.current_company_id(),
        forum_id: this.forumId
      }).subscribe((participants) => {
        this.participants = participants;
        this.list = this.list.concat(this.participants);
      });
      this.forumSurveySubmissionService.list({
        company_id: this.authenticationService.current_company_id(),
      }, {
        forum_id: this.forumId,
        status: 'submitted'
      }).subscribe((submissions) => {
        submissions.forEach(s => {
          if (!this.submissions[s.user_id]) {
            this.submissions[s.user_id] = 0;
          }
          this.submissions[s.user_id] += 1;
        });
      });
    });
  }

  getSortedList() {
    this.list.sort((a, b) => {
      const aLastName = a.client ? a.client.last_name : a.contact.last_name;
      const bLastName = b.client ? b.client.last_name : b.contact.last_name;
      return aLastName.localeCompare(bLastName);
    });
    return this.list;
  }
}
