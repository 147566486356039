import { Component, OnInit, Input } from '@angular/core';

import { getChoiceText, getTooltipText } from 'app/_utils/helpers';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
})
export class QuestionComponent implements OnInit {
  @Input() question: any;
  @Input() answers: any;
  @Input() disabled: boolean;
  @Input() index: number;
  indexWidth = 40;

  ngOnInit() {
  }

  getChoice(value) {
    return getChoiceText(value);
  }

  getTooltip(value) {
    return getTooltipText(value);
  }

  hasAnswer(value) {
    if (this.answers[this.question.id]) {
      return this.answers[this.question.id].split('\n').includes(value);
    }
    return false;
  }

  toggleAnswer(value) {
    const answers = this.answers[this.question.id] ? this.answers[this.question.id].split('\n') : [];
    if (this.hasAnswer(value)) {
      this.answers[this.question.id] = answers.filter(v => v !== value).join('\n');
    } else {
      answers.push(value);
      this.answers[this.question.id] = answers.join('\n');
    }
  }

  isDisabledAnswer(value) {
    if (this.answers[this.question.id] === 'N/A') {
      return value !== 'N/A';
    } else if (this.answers[this.question.id]) {
      return value === 'N/A';
    }
    return false;
  }
}
