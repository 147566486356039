<div>
  <h2 class="text-center">Forums</h2>
  <div class="panel panel-default">
    <div class="panel-body">
      <ul class="list">
        <li *ngFor="let forum of forums">
          <a [routerLink]="'/forums/' + forum.id">{{forum.name}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>