<h2 style="font-weight: bold;">OVERARCHING INITIATIVE OBJECTIVE</h2>
<ul style="font-size: 18px;">
  <li>
    To determine the feasibility of relating line items from commonly
    used major depressive disorder (MDD) and schizophrenia (SZ) clinical
    research scales to the Research Domain Criteria (RDoC) Domain matrix.
  </li>
  <li>
    The clinical research scales used in this initiative include: MADRS,
    HAM-D17, IDS-SR, HAM-A, SDS, PANSS and the PSP.
    <ul>
      <li>
        Keep in mind that at this stage this initiative is a feasibility
        study and that future clinical research scales may be added to
        represent additional mental health disorders, perspectives
        (e.g. self-report, clinician-report) and topics
        (e.g. functioning, cognition).
      </li>
      <li>
        Also note that we may find that commonly used scale items don't neatly
        fit into the RDoC Domain framework, or that the scale items are unevenly
        represented within the framework. Such discrepancies and nuances will
        build the foundation of our discussion on February 1st.
      </li>
    </ul>
  </li>
</ul>
