<nav class="navbar">
  <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
    <div id="account_nav">
      <div class="container">
        <ul class="nav navbar-nav navbar-right">
          <li *ngIf="!user"><a href="#">&nbsp;</a></li>
          <li class="m-r-40" *ngIf="user">
            <a href="javascript:void(0)">Welcome, {{user | fullName}}</a>
          </li>
          <!-- <li *ngIf="user">
            <a routerLink="/settings/profile">
              <i class="fa fa-user"></i> My Profile
            </a>
          </li> -->
          <li *ngIf="user">
            <a routerLink="/help">
              <i class="fa fa-question"></i> Help
            </a>
          </li>
          <li *ngIf="user">
            <a href="javascript:void(0)" (click)="logout()">
              <i class="fa fa-sign-out"></i> Log out
            </a>
          </li>
        </ul>
      </div>
      <div class="clearfix"></div>
    </div>
    <div id="main_nav">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" routerLink="/">
            <img src="/assets/img/logo.jpg">
          </a>
        </div>
        <ul class="nav navbar-nav navbar-right" *ngIf="user">
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/resources">Resources</a></li>
          <li><a routerLink="/directory" *ngIf="isModerator()">Directory</a></li>
          <!-- <li><a href="#">Tools</a></li> -->
        </ul>
        <ul class="nav navbar-nav" *ngIf="!user">
          <li><a href="#">&nbsp;</a></li>
        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</nav>