import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page2',
  templateUrl: './page2.component.html',
})
export class Page2Component implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }
}
