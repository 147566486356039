import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-survey-submission-modal',
  templateUrl: './survey-submission-modal.component.html',
})
export class SurveySubmissionModalComponent implements OnInit {
  status: string;
  survey: any;
  constructor(
    private router: Router,
    public bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
  }

  returnHome() {
    this.bsModalRef.hide();
    this.router.navigate(['/']);
  }
}
