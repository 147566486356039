import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService } from '../_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./component.css'],
})
export class HomeComponent extends BaseComponent implements OnInit {
  forums: any[];

  constructor(
    authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private forumService: ForumService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.forumService.list({
      company_id: this.getCurrentCompanyId(),
    }, {}).subscribe((forums) => {
      this.forums = forums;
      if (forums.length === 1) {
        this.router.navigate([`/forums/${forums[0].id}`]);
      }
    });
  }
}
