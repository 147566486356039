import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from 'app/_components/base.component';
import { AuthenticationService, ForumService, ForumSurveySubmissionService } from '../_services';

@Component({
  selector: 'app-forum-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./detail.component.css'],
})
export class ForumResourceListComponent extends BaseComponent implements OnInit {
  forumId: number;
  resources: any[];
  participants: any[];

  constructor(
    authenticationService: AuthenticationService,
    private forumService: ForumService,
    private forumSurveySubmissionService: ForumSurveySubmissionService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.forumService.list({
      company_id: this.getCurrentCompanyId(),
    }, {}).subscribe((forums) => {
      this.forumId = forums[0].id;
      this.forumService.getResources({
        company_id: this.getCurrentCompanyId(),
        forum_id: this.forumId
      }).subscribe((resources) => {
        this.resources = resources;
      });
      this.forumService.getParticipants({
        company_id: this.authenticationService.current_company_id(),
        forum_id: this.forumId
      }).subscribe((participants) => {
        this.participants = participants;
      });
    });
  }

  openPopup(resourceId) {
    window.open(`/forums/${this.forumId}/resources/${resourceId}`, 'MyWindow', 'width=1000,height=800');
    return false;
  }

  downloadSubmissionReport(participantId) {
    this.forumSurveySubmissionService.downloadReport({
      company_id: this.getCurrentCompanyId(),
    }, {
      forum_id: this.forumId,
      contact_id: participantId,
    }).subscribe(data => {
      const participant = this.participants.find(p => p.contact_id === participantId);
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'RDoC Initiative Digital Feedback Assignment.xlsx';
      if (participant) {
        link.download = 'RDoC Initiative Digital Feedback Assignment'
          + ` - ${participant.contact.first_name} ${participant.contact.last_name}.xlsx`;
      }
      link.click();
    });
  }
}
